<template>
	
	<!-- Main Sidebar -->
	<component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">
		<!-- Layout Header -->
		
		<a-layout-header style="padding: 10px;background-color: #fff;">
			
			<a-row type="flex">
			<a-col :span="14"  class="header_left_info">
				<img style="width: 120px;" src="https://mtai-1322455127.cos.ap-nanjing.myqcloud.com/logo/logo-2.png" alt="" />
				<a-menu v-model:selectedKeys="current" mode="horizontal"  style="margin-left: 20px;" @click="handleClick" />
					<a-menu mode="horizontal" >
					  <a-menu-item key="mail"><a href="/#/helper" >问答</a></a-menu-item>
				
					  <a-sub-menu>
					          <span slot="title" class="submenu-title-wrapper">绘图 <a-icon type="down"  /></span>
					            <a-menu-item key="setting:222">
					              <a href="/#/drawing/canvas?id=1">文生图</a>
					            </a-menu-item>
					  			<a-menu-item key="setting:443">
					  				<a href="/#/drawing/picture?id=2">图生图</a>
					  			</a-menu-item>
					  			<a-menu-item key="setting:554">
					  				<a href="/#/drawing/pictureArtqr">艺术二维码</a>
					  			</a-menu-item>
					  			<a-menu-item key="setting:335">
					  				<a href="/#/drawing/pictureHD">AI高清修复</a>
					  			</a-menu-item>
								<a-menu-item key="setting:336">
									<a href="/#/drawing/pictureface">AI图片换脸</a>
								</a-menu-item>
					  			<a-menu-item key="setting:667">
					  			    <a href="/#/drawing/picture2text">提取图片提示词</a>
					  			</a-menu-item>
					    </a-sub-menu>
					  <a-menu-item key="setting:1"><a href="/#/gptstore">AI工具</a></a-menu-item>
					  <a-menu-item key="setting:3"><a href="/#/ai_pdf">文档助手</a></a-menu-item>
					<a-menu-item key="setting:13">
					  <a href="/#/knowledge">AI知识库</a>
					</a-menu-item>
					  <a-sub-menu>
					          <span slot="title" class="submenu-title-wrapper">办公必备 <a-icon type="down" /></span>
					        <a-menu-item key="know" > <a href="/#/mark_me">思维导图</a></a-menu-item>
								<a-menu-item key="setting:4">
								  <a href="/#/ppt">AI生成PPT</a>
								</a-menu-item>
								<a-menu-item key="setting:5">
								  <a href="/#/ai_write">一键写作</a>
								</a-menu-item>
								
								
					    </a-sub-menu>
					<a-sub-menu >
					        <span slot="title" class="submenu-title-wrapper" > 更多 <a-icon type="down" /></span>
							<a-menu-item key="setting:55">
							 <a href="https://market.baidu.com/product/detail/4eec5f09-44b9-4cad-a7f5-a274713cb2b7" target="_blank" >企业服务</a>
							</a-menu-item>
							<a-menu-item key="setting:557">
							 <a href="https://www.ccopyright.net.cn" target="_blank" >元经纪官网</a>
							</a-menu-item>
					        <a-menu-item key="setting:66">
					           <a href="/#/dashboard">使用教程</a> 
					        </a-menu-item>
					</a-sub-menu>
					</a-menu>
				
				</a-col>
				
				<!-- / Header Breadcrumbs & Title Column -->

				<!-- Header Control Column -->
				<a-col :span="9" class="header-control" v-if="iswshow">
					<div class="user_info" v-if="userInfo">
						<a href="/#/profile" >
						<a-avatar :src="userInfo.avatar" />
						<span class="nickname">{{userInfo.nickname}}</span></a>
						
						<a v-if="userInfo.is_vip !== 1" href="/#/recharge?tabsId=2" style="background-color: #ffaa00;color: #fff;border-radius: 5px;padding: 5px 15px;margin-right: 10px;">开通会员</a>
						<a v-if="userInfo.is_vip == 1 && userInfo.is_qyvip !== 1" href="/#/recharge?tabsId=2" >
							<a-button style="margin-right: 10px;width: 80px;height: 30px;">
								<img style="width: 20px;margin-top: -5px;margin-left: -10px;" src="../../assets/imgs/vipp.png"/><span style="font-size: 12px;margin-top: 4px;margin-left: 3px;">VIP会员</span>
								</a-button>
						</a>
						<a v-if="userInfo.is_qyvip == 1" href="/#/recharge?tabsId=2" >
							<a-button style="margin-right: 10px;width: 85px;height: 30px;">
								<img style="width: 20px;margin-top: -5px;margin-left: -10px;" src="../../assets/imgs/vipp.png"/><span style="font-size: 12px;margin-top: 4px;margin-left: 3px;">企业会员</span>
								</a-button>
						</a>
						<a v-if="userInfo.is_vip !== 1" href="/#/recharge?tabsId=1" style="background-color: #00aaff;color: #fff;border-radius: 5px;padding: 5px 15px;margin-right: 10px;">积分充值</a>
					   
						<a href="/#/recharge?tabsId=1" style="margin-right: 0px;">
							<a-button style="margin-right: 10px;width: 110px;height: 30px;">
								<img style="width: 20px;margin-top: -5px;margin-left: -10px;" src="../../assets/imgs/jff.png"/><span style="font-size: 12px;margin-top: 4px;margin-left: 3px;">积分：{{ userInfo.usable || 0 }}</span>
								</a-button>
							</a>
						<a href="https://work.weixin.qq.com/kfid/kfc2eae9a90d39d9de5"  style="margin-right: 0px;">
							<a-button style="margin-right: 10px;width: 80px;height: 30px;">
								<img style="width: 20px;margin-top: -5px;" src="../../assets/imgs/tz.png"/><span style="font-size: 12px;margin-top: 4px;margin-left: 3px;">客服</span>
								</a-button>
							</a>
					</div>
					<div class="btn-sign-in" v-else @click="setLoginPopup(true)">
						<span style="color:#409eff;font-size: 14px;font-weight: 500;">登录/注册</span>
					</div>
				</a-col>
				<a-col :span="1"   v-if="iswshow">
					<a-button type="link" ref="secondarySidebarTriggerBtn" @click="$emit('toggleSettingsDrawer', true)">
						<a-icon type="setting" style="font-size: 18px;margin-top: 5px;color:#000"   />
					</a-button>
				</a-col>
				<!-- / Header Control Column -->
				
			</a-row>
		
		</a-layout-header>
		<!--  /Layout Header -->
       <a-modal v-if="config.contact_us && config.contact_us.content" v-model="showModal" :width="400" :footer="null" :centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="contact" v-html="config.contact_us.content"></div>
			</div>
		</a-modal>
		
		<LoginCard></LoginCard>
		
	</component>
	
	<!-- / Main Sidebar -->
</template>

<script>
	
	import LoginCard from "@/components/Custom/LoginCard.vue"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: { LoginCard },
		props: {
			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false
			},
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false
			},
			// Header notifications data.
		
		},
		data() {
			return {
				// Fixed header/sidebar-footer ( Affix component ) top offset.
				top: 0,
				current : ['mail'],
				iswshow:true,
				fullWidth: 0,
				// Search input loading status.
				searchLoading: false,
				// The wrapper element to attach dropdowns to.
				wrapper: document.body,
				showModal: false,
				showModelType: "wxoa" ,// wxoa wxscan contact
				
			}
		},
		computed: {
			...mapState("app", ["config", "sign"]),
			...mapGetters("user", ["userInfo"])
		},
		watch: {},
		created() {
			// Registering window resize event listener to fix affix elements size
			// error while resizing.
			window.addEventListener("resize", this.resizeEventHandler)
			window.addEventListener('resize', this.handleResize)
		},
		mounted() {
			// Set the wrapper to the proper element, layout wrapper.
			this.wrapper = document.getElementById("layout-dashboard")
            // document.getElementById("side").click();
		},
		destroyed() {
			// Removing window resize event listener.
			window.removeEventListener("resize", this.resizeEventHandler)
		},
		 beforeDestroy: function () {
		      window.removeEventListener('resize', this.handleResize)
		    },
		methods: {
			...mapMutations("user", ["setLoginPopup"]),
			// 面包屑
			downs(){
				console.log(1);
				
               window.open("https://www.mt180.com/downloads/", '_blank');
				
			},
			// 生成二维码
			creatQrCode() {
				// 重复创建二维码会，之前的二维码还在，新创建的会被接在下面
				// 此行代码是为了解决以上问题 或者 刷新二维码的时候生成多个二维码的问题
				if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = ""
				new QRCode(this.$refs.qrCodeUrl, {
					text: this.config.h5, // 需要转换为二维码的内容
					width: 340,
					height: 340,
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			// 按钮
			rightButtonHandle(type) {
				this.showModelType = type
				this.showModal = true
				this.$nextTick(() => {
					type == "wxscan" && this.creatQrCode()
				})
			},
			breadcrumb(data) {
				return data && data.filter(item => item.name && item.meta.title != "首页")
			},
			handleClick(e){
				
			},
			resizeEventHandler() {
				this.top = this.top ? 0 : -0.01
			},
			handleResize (event) {
			        this.fullWidth = document.documentElement.clientWidth;
			        // 页面宽度小于750px时，不显示地图
			        if (this.fullWidth < 1200) {
			          this.iswshow = false;
			        } else {
			          this.iswshow = true;
			        }
			      },
			onSearch(value) {}
		}
	}
</script>


<style lang="scss" scoped>
	::v-deep .ant-modal-header {
		border: none;
	}

	.btn-sign-in {
		cursor: pointer;
	}

	.user_info {
		.nickname {
			margin: 0 10px;
			font-weight: 600;
			color: #000;
		}
	}
	.ant-menu-item{
		border-bottom: 0px;
	
	}
	.ant-menu-horizontal{
		border-bottom: 0px;
	}
  .top_box{
      margin-left: 20px;
  }

</style>
